<template>
  <Heading>
    {{ translations.APP_TITLE }}
  </Heading>

  <PageActions
      :secondaryAction="{ content: this.translations.BTN_RESET, destructive: true, onClick: reset  }"
  />
<!--  <PageActions-->
<!--      :primaryAction="{ content: 'Save', onClick: handleClick }"-->
<!--      :secondaryActions="[{ content: 'Delete', destructive: true, onClick: handleClick  }, { content: 'Clear', disabled: true }]"-->
<!--  />-->

  <Layout>

    <Frame v-if="isLoading && 1===2">
      <Loading />
    </Frame>

    <LayoutSection>

      <!-- 1 - Enter order details section -->
      <AnnotatedSection v-if="!customerOrder || currentPage === 1">
        <template #title>{{ translations.REQUEST_RMA }}</template>
        <template #description>
          <p v-if="!this.isLoading">
            {{ translations.ENTER_ORDER_DETAILS }}
          </p>
        </template>
        <FormLayout v-if="!isLoading">
          <div v-for="(message, index) in this.messages" :key="index">
            <InlineError fieldID="myFieldID" :message="message"/>
          </div>
          <TextField v-model="email" autoComplete="off" type="email" placeholder="Email">
            <template #label>{{ translations.EMAIL_ADDRESS }}</template>
          </TextField>
          <TextField v-model="orderToken" type="text" placeholder="Order token">
            <template #label>{{ translations.ORDER_TOKEN }}</template>
          </TextField>
          <Button v-on:click="getOrder()" v-if="!isLoading && email && orderToken" primary submit >
            {{ translations.CONTINUE }}
          </Button>
        </FormLayout>
        <Spinner v-if="this.isLoading" />
      </AnnotatedSection>

      <!-- 2 - Select order item lines to return -->
      <FormLayout v-if="customerOrder && returnLinesSelected === false && currentPage === 2">

        <Select
            placeholder="Select an option"
            v-model="this.lang"
            :options="this.langOptions"
            @change="onSelectionLanguageChange"
        >
<!--          <template #label>Date Range</template>-->
        </Select>

        <Card sectioned :title="this.translations.SELECT_RMA_ITEMS_TITLE">

          <p>
            {{ translations.SELECT_ITEMS_TEXT }}
          </p>
          <div v-for="(message, index) in this.messages" :key="index">
            <InlineError fieldID="myFieldID" :message="message"/>
          </div>
          <ResourceList
              :resourceName="{singular: 'item', plural: 'items'}"
              :selectedItems="this.selectedCustomerOrderLineIds"
              selectable
              @selection-change="onSelectionCustomerOrderLinesChange"
          >
            <ResourceItem
                v-for="item in this.customerOrderLines"
                :key="item.id"
                :id="`${item.id}`"
            >
              <Grid
                  :columns="{ xs: 1, sm: 4, md: 4, lg: 6, xl: 6 }"
                  :areas="{ xs: ['image', 'product'],
                  sm: [
                    'image image product product',
                    'image image product product',
                    ],
                    md: ['image product product product'],
                    lg: ['image product product product product product'],
                    xl: ['image product product product product product'],
                  }"
              >
                <GridCell area="image">
                  <Thumbnail v-bind="{ size: 'large', source: getImageThumbBySku(item.sku) }" />
                </GridCell>
                <GridCell area="product">
                  <h3>
                    <TextStyle variation="strong">{{ item.title }}</TextStyle>
                  </h3>
                  {{ translations.SKU }}: {{ item.sku }}<br />
                  {{ translations.QUANTITY_ORDERED }}: {{ item.quantity }}<br />
                  {{ translations.QUANTITY_AVAILABLE }}: {{ item.quantity_to_return }}
                </GridCell>
              </Grid>
            </ResourceItem>
          </ResourceList>
        </Card>
        <Button v-on:click="confirmOrderLineSelection()" primary submit >
          {{ translations.CONTINUE }}
        </Button>
      </FormLayout>

      <!-- 3 - Loop through selected order lines and show reasons -->
      <FormLayout v-if="customerOrder && returnLinesSelected && currentPage === 3">
        <Card sectioned :title="this.translations.RMA_ITEM_QUANTITIES_TITLE">
          <p>
            {{ translations.RMA_ITEM_QUANTITIES_TEXT }}
          </p>
          <div v-for="(message, index) in this.messages" :key="index">
            <InlineError fieldID="myFieldID" :message="message"/>
          </div>
        </Card>
        <Card v-for="(rmaItem, index) in getItemsToReturn"
              :key="index"
              sectioned
              :title="rmaItem['title']">
          <Grid
              :columns="{ xs: 1, sm: 4, md: 4, lg: 6, xl: 6 }"
              :areas="{ xs: ['image', 'product', 'action'],
                sm: [
                  'image product product action',
                  'image product product action',
                ],
                md: ['image product product action'],
                lg: ['image product product product product action'],
                xl: ['image product product product product action'],
              }"
          >
            <GridCell area="image">
              <Thumbnail v-bind="{ size: 'large', source: getImageThumbBySku(rmaItem['sku']) }" />
            </GridCell>
            <GridCell area="product">
              <TextContainer>
                <p style="text-align: left;">
                  {{ translations.ORDER_LINE_ID }}: {{ rmaItem['id'] }}<br />
                  {{ translations.SKU }}: {{ rmaItem['sku'] }}<br />
                  {{ translations.MAX_RETURN_QUANTITY }}: {{ rmaItem['quantity_to_return'] }}<br />
                  {{ translations.AVAILABLE_RETURN_QUANTITY }}: {{ getAvailableReturnQuantityByOrderLineId(rmaItem['id']) }}<br />
                  {{ translations.ENTER_RETURN_QUANTITY }}: {{ getReturnQuantityByOrderLineId(rmaItem['id']) }}<br />
                </p>
              </TextContainer>
            </GridCell>
            <GridCell area="action">
              <Button v-if="rmaItem['id'] !== currentCustomerOrderLine && getAvailableReturnQuantityByOrderLineId(rmaItem['id']) > 0" v-on:click="setCurrentCustomerOrderLine(rmaItem['id'])"
                      primary
                      submit>
                {{ translations.ADD_TO_RMA }}
              </Button>
            </GridCell>
          </Grid>
        </Card>
        <Card>
          <ResourceList
              :resourceName="{singular: this.translations.RMA_ITEM, plural: this.translations.RMA_ITEMS}"
              :selectedItems="selectedRmaItemsToDelete"
              @selection-change="onSelectionChangeRmaItemsToDelete"
              :bulkActions="[{ content: this.translations.BTN_DELETE, onAction: () => this.removeRmaItems(), }]"
              selectable
          >
            <ResourceItem
                v-for="item in this.rmaItems"
                :key="item.customerOrderLineId"
                :id="`${item.customerOrderLineId}`"
                :selectable="item.quantity_to_return > 0"
            >
              <template #media>
                <Thumbnail v-bind="{ size: 'large', source: getRmaItemImageByOrderLineId(item.customerOrderLineId) }" />
              </template>
              <h3>
                <TextStyle variation="strong">
                  {{ getRmaItemSkuByOrderLineId(item.customerOrderLineId) }} - {{ getRmaItemTitleByOrderLineId(item.customerOrderLineId) }}
                </TextStyle>
              </h3>
              <Stack vertical no-item-wrap>
                <StackItem>
                  {{ translations.QUANTITY }}: {{ item.quantity }} - Reason: {{ getReturnReasonTitleByReasonId(item.reasonId) }}
                </StackItem>
                <StackItem>
                  <Button v-on:click="removeRmaItem(item.key)"
                          primary
                          submit
                          destructive="destructive"
                  >
                    {{ translations.BTN_DELETE }}
                  </Button>
                </StackItem>
              </Stack>
            </ResourceItem>
          </ResourceList>
        </Card>

        <Button v-on:click="confirmRmaItemQuantities()" primary submit >{{ translations.CONTINUE }}</Button>

        <Modal :open="currentCustomerOrderLine !== null" @close="setCurrentCustomerOrderLine(null)">
          <template #title>{{ translations.ADD_ITEMS_TO_RMA }}</template>
          <template #content>
            <ModalSection>
              <Stack vertical no-item-wrap>
                <StackItem>
                  <TextContainer>
                    <p>
                      {{ translations.ENTER_QUANTITY }}
                      {{ translations.MAX_RETURN_QUANTITY_IS }}: {{ getAvailableReturnQuantityByOrderLineId(currentCustomerOrderLine) }}
                    </p>
                  </TextContainer>
                  <Form @submit="closeRmaModel();" data-submit="setCurrentCustomerOrderLine">
                    <InlineError v-if="rmaItemInputQty > getAvailableReturnQuantityByOrderLineId(currentCustomerOrderLine)"
                                 fieldID="rmaItemInputQty"
                                 message="Incorrect amount"/>
                    <FormGroup condensed>
                      <TextField
                          id="rmaItemInputQty"
                          v-model="rmaItemInputQty"
                          type="number"
                          name="quantity"
                          placeholder="Quantity"
                          min="1"
                          :max="getAvailableReturnQuantityByOrderLineId(currentCustomerOrderLine)"
                          value="0"
                      />
                    </FormGroup>
                    <FormGroup condensed>
                      <Select
                          name="reason"
                          v-model="rmaItemInputReason"
                          :options="getReasonChoices"
                          :requiredIndicator="true"
                          :placeholder="this.getReturnReasonTitleByReasonId(rmaItemInputReason)"
                      >
                        <template #label>{{ translations.SELECT_REASON }}</template>
                      </Select>
                      <Select
                          name="resolution"
                          v-model="rmaItemInputResolution"
                          :options="getResolutionChoices"
                          :requiredIndicator="true"
                          :placeholder="this.getReturnResolutionTitleByResolutionId(rmaItemInputResolution)"
                      >
                        <template #label>{{ translations.SELECT_RESOLUTION }}</template>
                      </Select>
                    </FormGroup>
                    <FormGroup condensed v-if="rmaItemInputQty > 0 && rmaItemInputQty <= getAvailableReturnQuantityByOrderLineId(currentCustomerOrderLine) && rmaItemInputReason > 0 && rmaItemInputResolution > 0">
                      <Button v-on:click="addRmaItem(currentCustomerOrderLine, rmaItemInputQty, rmaItemInputReason, rmaItemInputResolution)"
                              primary
                              submit >
                        {{ translations.CONFIRM }}
                      </Button>
                    </FormGroup>
                  </Form>
                </StackItem>
              </Stack>
            </ModalSection>
          </template>
        </Modal>
      </FormLayout>

      <!-- 4 - Enter RMA questions -->
      <!--    <Form @submit="confirmReasons();" data-submit="setCurrentCustomerOrderLine" v-if="returnRmaItemsEntered && currentPage === 4">-->
      <Form @submit="submitRma" v-if="returnRmaItemsEntered && currentPage === 4">
        <FormLayout>
          <Card v-for="(rmaItem, index) in this.rmaItems"
                :key="index"
                sectioned
                :title="rmaItem.title">
            <!--        {{ rmaItem }}-->
            <TextContainer>
              <Heading>
                {{ getRmaItemSkuByOrderLineId(rmaItem.customerOrderLineId)  }}
                -
                {{ getRmaItemTitleByOrderLineId(rmaItem.customerOrderLineId) }}
                |
                {{ getReturnReasonTitleByReasonId(rmaItem.reasonId) }}
              </Heading>
            </TextContainer>
            <div v-for="reasonQuestionId, questionIndex, index in rmaItem.answerData" :key="questionIndex">

              <TextContainer v-if="index === 0">
                <p><br />{{ rmaItem.reasonDescription }}<br /><br /></p>
              </TextContainer>

              <!-- Image upload -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'image' && rmaItem.questionData[questionIndex].enabled === true">
                <p>{{ rmaItem.questionData[questionIndex].question }}</p>
                <DropZone @drop="handleDrop"
                          :id="this.getQuestionInputName(rmaItem.key, questionIndex, 'input')"
                          :title="rmaItem.questionData[questionIndex].questionText"
                          :accept="this.validImageTypes"
                >
                  <DropZoneFileUpload v-if="!rmaItem.answerData[questionIndex].length"
                                      actionHint="Accepts .gif, .jpg, and .png"
                                      type="image"
                                      :accept="this.validImageTypes"
                  />
                  <Stack v-else>
                    <Stack v-for="file, index in rmaItem.answerData[questionIndex]" :key="index">
                      <Thumbnail size="small" :alt="file.name" :source="this.thumbnailSource(file)"/>
                      <div>{{ file.name }}
                        <Caption>{{ file.size }} bytes</Caption>
                      </div>
                    </Stack>
                  </Stack>
                </DropZone>
                <p>{{ rmaItem.questionData[questionIndex].instructions }}</p>
                <p>&nbsp;</p>
              </div>

              <!-- Video upload -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'video' && rmaItem.questionData[questionIndex].enabled === true">
                <p>{{ rmaItem.questionData[questionIndex].question }}</p>
                <DropZone @drop="handleDrop"
                          :id="this.getQuestionInputName(rmaItem.key, questionIndex, 'input')"
                          :title="rmaItem.questionData[questionIndex].questionText"
                          :accept="this.validVideoTypes"
                >
                  <DropZoneFileUpload v-if="!rmaItem.answerData[questionIndex].length"
                                      actionHint="Accepts .mp4, etc."
                                      type="image"
                                      :accept="this.validVideoTypes"
                  />
                  <Stack v-else>
                    <Stack v-for="file, index in rmaItem.answerData[questionIndex]" :key="index">
                      <Thumbnail size="small" :alt="file.name" :source="this.thumbnailSource(file)"/>
                      <div>{{ file.name }}
                        <Caption>{{ file.size }} bytes</Caption>
                      </div>
                    </Stack>
                  </Stack>
                </DropZone>
                <p>{{ rmaItem.questionData[questionIndex].instructions }}</p>
                <p>&nbsp;</p>
              </div>

              <!-- Select 1 option -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'select-1' && rmaItem.questionData[questionIndex].enabled === true">
                <Select
                    v-model="rmaItem.answerData[questionIndex]"
                    :name="this.getQuestionInputName(rmaItem.key, questionIndex)"
                    :options="this.getQuestionDataSelectOptions(rmaItem.questionData[questionIndex].questionData)"
                    :requiredIndicator="true"
                    :required="true"
                    placeholder="Select answer"
                >
                  {{ rmaItem.questionData[questionIndex].question }}
                </Select>
                {{ rmaItem.questionData[questionIndex] }}
                <p>&nbsp;</p>
              </div>

              <!-- Select multiple options -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'select' && rmaItem.questionData[questionIndex].enabled === true">
                <ChoiceList
                    v-model="rmaItem.answerData[questionIndex]"
                    :name="this.getQuestionInputName(rmaItem.key, questionIndex)"
                    :allowMultiple="true"
                    :choices="this.getQuestionDataSelectOptions(rmaItem.questionData[questionIndex].questionData)"
                    :requiredIndicator="true"
                    :required="true"
                    placeholder="Select answer"
                >
                  {{ rmaItem.questionData[questionIndex].question }}
                </ChoiceList>
                <p>&nbsp;</p>
              </div>

              <!-- Yes/No (with possible other labels for yes and no) -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'boolean' && rmaItem.questionData[questionIndex].enabled === true">
                <ChoiceList
                    v-model="rmaItem.answerData[questionIndex]"
                    :name="this.getQuestionInputName(rmaItem.key, questionIndex)"
                    :allowMultiple="false"
                    :choices="this.getQuestionDataSelectOptions(rmaItem.questionData[questionIndex].questionData)"
                    :requiredIndicator="true"
                    :required="true"
                    placeholder="Select answer"
                >
                  {{ rmaItem.questionData[questionIndex].question }}
                </ChoiceList>
                <p>&nbsp;</p>
              </div>

              <!-- Numeric -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'numeric' && rmaItem.questionData[questionIndex].enabled === true">
                <TextField
                    type="number"
                    v-model="rmaItem.answerData[questionIndex]"
                    :name="this.getQuestionInputName(rmaItem.key, questionIndex)"
                    autoComplete="off"
                    :requiredIndicator="true"
                    :required="true"
                >
                  {{ rmaItem.questionData[questionIndex].question }}
                </TextField>
                <p>&nbsp;</p>
              </div>

              <!-- Text field -->
              <div v-if="rmaItem.questionData[questionIndex].answerType === 'text' && rmaItem.questionData[questionIndex].enabled === true">
                <p>{{ rmaItem.questionData[questionIndex].title }}</p>
                <TextField
                    type="textarea"
                    v-model="rmaItem.answerData[questionIndex]"
                    :name="this.getQuestionInputName(rmaItem.key, questionIndex)"
                    autoComplete="off"
                    :requiredIndicator="true"
                    :required="true"
                    :label="rmaItem.questionData[questionIndex].question"
                    :multiline="4"
                ></TextField>
                <p>&nbsp;</p>
              </div>

            </div>

            <!-- General conditions -->
            <TextContainer v-if="this.config.generalConditionsUrl !== null">
                <Checkbox
                        v-model="this.acceptedGeneralConditions"
                        v-if="this.config.generalConditionsUrl !== null"
                        :required="true"
                        :helpText="this.translations.ACCEPT_GENERAL_CONDITIONS"
                >
                    <template #label></template>
                </Checkbox>
                {{ translations.ACCEPT_GENERAL_CONDITIONS }}
                <Link :external=true :monochrome=true :url="this.config.generalConditionsUrl" target="_blank">
                    {{ this.config.generalConditionsUrl }}
                </Link>
            </TextContainer>
          </Card>

          <Card v-if="this.showComments === true" style="padding: 15px;">
            <TextContainer>
              <Heading>
                {{ translations.COMMENTS }}
              </Heading>
            </TextContainer>
            <div>
              <TextField
                  v-model="this.comments"
                  autoComplete="off"
                  label=""
                  :multiline="5"
              />
            </div>
          </Card>

          <Button v-if="getAllRmaQuestionsAnswered()" type="submit" :disabled="this.isLoading" primary submit>{{ translations.SUBMIT_RMA }}</Button>

          <div v-for="(message, index) in this.messages" :key="index">
            <InlineError fieldID="myFieldID" :message="message"/>
          </div>

        </FormLayout>
      </Form>

      <!-- 5 - RMA is sent, upload images -->
      <Card v-if="rmaSubmitted && currentPage === 5" sectioned :title="this.translations.UPLOADING_TITLE">
        <div v-for="(message, index) in this.messages" :key="index">
          <InlineError fieldID="myFieldID" :message="message"/>
        </div>
        <TextContainer>
          <p>{{ translations.UPLOADING }}</p>
        </TextContainer>
      </Card>

      <!-- 6 uploads finished -->
      <Card v-if="rmaSubmitted && currentPage === 6" sectioned :title="this.translations.THANKYOU_TITLE">
        <div v-for="(message, index) in this.messages" :key="index">
          <InlineError fieldID="myFieldID" :message="message"/>
        </div>
        <TextContainer>
          <p>{{ translations.THANKYOU }}</p>
        </TextContainer>
      </Card>

    </LayoutSection>

    <!-- Pagination -->
    <LayoutSection>
      <Pagination
          v-if="this.currentPage > 1 && this.currentPage < 5"
          :has-previous="(this.currentPage - 1) > 0"
          :has-next="this.lastPage > this.currentPage"
          @previous="handlePrevious"
          @next="handleNext"
      >
        {{ translations.RESULTS }}
      </Pagination>
    </LayoutSection>

  </Layout>

  <!-- Debug info -->
  <FooterHelp>
<!--    <p v-if="currentPage === 1">-->
<!--      <a href="#" v-on:click="setTestCustomer()">Set test customer</a>-->
<!--    </p>-->

<!--    <div v-if="debug">-->
<!--      <p>-->
<!--        <a href="#" v-on:click="setTestCustomer()">Set test customer</a>-->
<!--      </p>-->

<!--      Pid: {{ pid }}<br />-->
<!--      ApiUri: {{ apiUri }} <br />-->
<!--      Vendor: {{ config.vendor }}<br />-->
<!--      Platform: {{ config.platform }}<br />-->
<!--      Email: {{ email }}<br />-->
<!--      OrderToken: {{ orderToken }}<br />-->
<!--      Lang: {{ lang }}<br />-->
<!--    </div>-->
  </FooterHelp>

</template>


<script>
import axios from "axios";
import {
  Layout,
  Card,
  FormLayout,
  TextField,
  PageActions,
  Heading,
  FooterHelp,
  TextContainer,
  FormGroup,
  Caption,
  //MediaCard,
  //CardSection, // CardSubsection,
  // CardHeader, // Popover,
  Modal,
  ModalSection,
  Stack,
  StackItem,
  Thumbnail,
  ChoiceList,
  DropZone,
  DropZoneFileUpload,
  Checkbox,
  // Select,
  Button,
  Grid,
  GridCell,
  ResourceList,
  ResourceItem,
  InlineError,
  Loading,
  Spinner,
  //DataTable,
  TextStyle,
  Pagination,
  //Subheading,
  Select,
  Frame,
  AnnotatedSection,
  LayoutSection,
  //MediaCard,
  //Page,
  //Badge
} from '@ownego/polaris-vue';

//axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

export default {
  name: 'RmaCentra',
  components: {
    Frame,
    Select,
    //Subheading,
    Button,
    // Select,
    Layout,
    Card,
    Caption,
    FormLayout,
    TextField,
    PageActions,
    Heading,
    FooterHelp,
    TextContainer,
    FormGroup,
    DropZone,
    DropZoneFileUpload,
    TextStyle,
    Modal,
    ModalSection,
    Stack,
    StackItem,
    //Page,
    //Badge
    //DataTable, TextStyle
    //MediaCard,
    ChoiceList,
    Checkbox,
    Grid,
    GridCell,
    Thumbnail,
    ResourceList,
    ResourceItem,
    Pagination,
    InlineError,
    Loading,
    AnnotatedSection,
    LayoutSection,
    Spinner,
    //MediaCard,
    //CardSection, // CardSubsection,
    // CardHeader, // Popover,
    // Stack
  },
  props: {

  },
  data() {
    return {
      pid: null,
      host: 'www.rmacentra.nl',
      apiUri: null,
      vendor: null,
      platform: null,
      showComments: false,
      lang: 'en',
      langOptions: [
        { "label": "English", "value": "en" }
      ],
      config: [],
      debug: false,
      validImageTypes: ['image/gif', 'image/jpeg', 'image/png'],
      validVideoTypes: ['video/mp4', 'video/3gpp', 'video/quicktime', 'application/x-mpegURL', 'video/MP2T', 'video/x-msvideo', 'video/x-ms-wmv'],

      // Order data parsed from order payload, messages and translations
      customerOrder: null,
      customerOrderLines: null,
      messages: [],
      translations: [],

      // Entered data
      email: '',
      orderToken: '',
      comments: '',

      isLoading: true,
      currentPage: 1,
      lastPage: 1,
      toastActive: false,
      selectedCustomerOrderLineIds: [],
      returnLinesSelected: false,
      rmaCustomerOrderItemQytInputs: [], // To bind the quantity inputs
      currentCustomerOrderLine: null,
      returnRmaItemsEntered: false,
      rmaItems: [],
      rmaItemQuestionAnswers: [],
      selectedRmaItemsToDelete: [],
      acceptedGeneralConditions: false,
      rmaSubmitted: false,

      rmaItemInputQty: 0,
      rmaItemInputReason: '',
      rmaItemInputResolution: '',
    }
  },
  computed: {
    getItemsToReturn: function() {
      let result = [];
      for (let index in this.customerOrderLines) {
        let customerOrderLine = this.customerOrderLines[index];
        if (this.selectedCustomerOrderLineIds.indexOf(customerOrderLine['id'].toString()) >= 0) {
          result.push(customerOrderLine);
        }
      }
      return result;
    },
    getReasonChoices: function() {
      let result = [];
      for (let index in this.config.reasonQuestionData) {
        let reasonQuestion = this.config.reasonQuestionData[index];
        result.push({
          'value' : reasonQuestion.id,
          'label' : reasonQuestion.title,
          //'helpText' : reasonQuestion.title,
        })
      }
      return result;
    },
    getResolutionChoices: function() {
      let result = [];
      for (let index in this.config.resolutionData) {
        let reasonResolution = this.config.resolutionData[index];
        result.push({
          'value' : reasonResolution.id,
          'label' : reasonResolution.title,
          //'helpText' : reasonQuestion.title,
        })
      }
      return result;
    },
  },
  methods: {
    reset() {
      this.email = '';
      this.orderToken = '';
      this.customerOrder = null;
      this.customerOrderLines = null;

      this.messages = [];
      this.selectedCustomerOrderLineIds = [];
      this.returnLinesSelected = false;
      this.returnRmaItemsEntered = false;
      this.rmaItems = [];
      this.rmaItemQuestionAnswers = [];
      this.currentCustomerOrderLine = null;
      this.selectedRmaItemsToDelete = [];
      this.acceptedGeneralConditions = false;
      this.rmaSubmitted = false;
      this.currentPage = 1;
      this.lastPage = 1;
      this.isLoading = false;
      this.translations = [];
    },
    // setTestCustomer() {
    //   this.email = 'movilquick.elda@gmail.com';
    //   this.orderToken = '8bb42d730f25868b564b3204ee2d22b0';
    // },
    getOrder() {
      let apiUrl = this.apiUri;
      let email = this.email;
      let orderToken = this.orderToken;
      let pid = this.pid;
      axios.get(apiUrl + '/api/v1/order?email=' + email + '&token=' + orderToken + '&pid=' + pid)
          .then(response => {
            this.setMessagesFromResponse(response);
            if (this.messages.length === 0) {
              this.setCustomerOrder(response);
              this.setCurrentPage(2);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    setCustomerOrder(response) {
      if (response.data.customer_order !== undefined) {
        this.customerOrder = response.data.customer_order;
        if (response.data.customer_order.customer_order_lines !== undefined) {
          this.customerOrderLines = response.data.customer_order.customer_order_lines;
        }
      }
    },
    onSelectionLanguageChange: function() {
      let url = new URL(window.location);
      url.searchParams.set('lang', this.lang);
      window.location = url.href;
    },
    getImageThumbBySku: function(sku) {
      let src = false;
      for (let index in this.customerOrder.product_thumbs) {
        let image = this.customerOrder.product_thumbs[index];
        if (image['sku'] === sku) {
          src = image['src'];
        }
      }
      return src;
    },
    getRmaItemSkuByOrderLineId(orderLineId) {
      let sku = '';
      for (let customerOrderLineIndex in this.customerOrderLines) {
        let customerOrderLine = this.customerOrderLines[customerOrderLineIndex];
        if (orderLineId === customerOrderLine['id']) {
          sku = customerOrderLine['sku'];
        }
      }
      return sku;
    },
    getRmaItemTitleByOrderLineId(orderLineId) {
      let title = '';
      for (let customerOrderLineIndex in this.customerOrderLines) {
        let customerOrderLine = this.customerOrderLines[customerOrderLineIndex];
        if (orderLineId === customerOrderLine['id']) {
          title = customerOrderLine['title'];
        }
      }
      return title;
    },
    getRmaItemImageByOrderLineId(orderLineId) {
      let rmaItemSku = this.getRmaItemSkuByOrderLineId(orderLineId);
      return this.getImageThumbBySku(rmaItemSku);
    },
    getReturnQuantityByOrderLineId(orderLineId) {
      let returnQuantity = 0;
      for (let rmaItemIndex in this.rmaItems) {
        let rmaItem = this.rmaItems[rmaItemIndex];
        if (parseInt(orderLineId) === parseInt(rmaItem['customerOrderLineId'])) {
          returnQuantity = returnQuantity + parseInt(rmaItem['quantity']);
        }
      }
      return returnQuantity;
    },
    getQuantityToReturnByOrderLineId(orderLineId) {
      let returnQuantity = 0;
      for (let customerOrderLineIndex in this.customerOrderLines) {
        let customerOrderLine = this.customerOrderLines[customerOrderLineIndex];
        if (parseInt(orderLineId) === parseInt(customerOrderLine['id'])) {
          returnQuantity = returnQuantity + parseInt(customerOrderLine['quantity_to_return']);
        }
      }
      return returnQuantity;
    },
    getReturnReasonTitleByReasonId(reasonId) {
      let title = this.translations.SELECT_REASON;
      for (let reasonQuestionIndex in this.config.reasonQuestionData) {
        let reasonQuestion = this.config.reasonQuestionData[reasonQuestionIndex];
        if (parseInt(reasonId) === parseInt(reasonQuestion['id'])) {
          title = reasonQuestion['title'];
        }
      }
      return title;
    },
    getReturnResolutionTitleByResolutionId(resolutionId) {
      let title = this.translations.SELECT_REASON;
      for (let resolutionIndex in this.config.resolutionData) {
        let resolution = this.config.resolutionData[resolutionIndex];
        if (parseInt(resolutionId) === parseInt(resolution['id'])) {
          title = resolution['title'];
        }
      }
      return title;
    },
    getAvailableReturnQuantityByOrderLineId: function(orderLineId) {
      let quantityToReturn = this.getQuantityToReturnByOrderLineId(orderLineId);
      let returnQuantity = this.getReturnQuantityByOrderLineId(orderLineId);
      return (quantityToReturn - returnQuantity);
    },
    getReasonQuestionDataByReasonId(reasonId) {
      let result = [];
      for (let index in this.config.reasonQuestionData) {
        let reasonQuestion = this.config.reasonQuestionData[index];
        if (parseInt(reasonId) === parseInt(reasonQuestion.id)) {
          result = reasonQuestion.questionData;
        }
      }
      return result;
    },
    limitMaxQuantity(value, maxValue) {
      let newValue;
      if (value < 0) {
        newValue = 0;
      } else if(value > maxValue) {
        newValue = maxValue;
      } else {
        newValue = value;
      }
      return newValue;
    },
    confirmOrderLineSelection() {
      if (this.selectedCustomerOrderLineIds.length > 0) {
        this.returnLinesSelected = true;
        this.clearMessages();
        this.setCurrentPage(3);
      } else {
        this.setMessage(this.translations.SELECT_ITEMS)
      }
    },
    confirmRmaItemQuantities() {
      if (this.rmaItems.length > 0) {
        this.returnRmaItemsEntered = true;
        this.setCurrentPage(4);
        this.initQuestionAnswerData();
      } else {
        this.setMessage(this.translations.SELECT_RMA_ITEMS)
      }
    },
    onSelectionCustomerOrderLinesChange(customerOrderLineId) {
      this.selectedCustomerOrderLineIds = customerOrderLineId;
    },
    onSelectionChangeRmaItemsToDelete(customerOrderLineId) {
      this.selectedRmaItemsToDelete = customerOrderLineId;
    },
    setCurrentCustomerOrderLine(customerOrderLineId) {
      this.currentCustomerOrderLine = customerOrderLineId;
      this.rmaItemInputQty = 0;
      this.rmaItemInputResolution = '';
      this.rmaItemInputReason = '';
    },
    addRmaItem(customerOrderLineId, quantity, reasonId, resolutionId) {
      let availableQuantity = this.getAvailableReturnQuantityByOrderLineId(customerOrderLineId);
      let lineAvailable = false;
      let combinedKey = customerOrderLineId + '-' + reasonId + '-' + resolutionId;

      if (availableQuantity >= quantity) {
        for (let rmaItemIndex in this.rmaItems) {
          let rmaItem = this.rmaItems[rmaItemIndex];
          if (
              combinedKey === rmaItem['key']
          ) {
            this.rmaItems[rmaItemIndex]['quantity'] = (parseInt(rmaItem['quantity']) + parseInt(quantity));
            lineAvailable = true;
          }
        }
        if (!lineAvailable && quantity > 0 && reasonId > 0 && resolutionId > 0) {
          let rmaItem = {
            'key': combinedKey,
            'customerOrderLineId': customerOrderLineId,
            'quantity': quantity.toString(),
            'reasonId': reasonId,
            'resolutionId': resolutionId,
            'answerData': [],
            'questionData': []
          };
          this.rmaItems.push(rmaItem);
        }
      }
      this.closeRmaModel();
    },
    removeRmaItem(key) {
      let newRmaItems = [];
      for (let rmaItemIndex in this.rmaItems) {
        let rmaItem = this.rmaItems[rmaItemIndex];
        if (
            key !== rmaItem['key']
        ) {
          newRmaItems.push(rmaItem);
        }
      }
      this.rmaItems = newRmaItems;
    },
    removeRmaItems() {
      let newRmaItems = [];
      for (let rmaItemToDeleteId in this.selectedRmaItemsToDelete) {
        for (let rmaItemIndex in this.rmaItems) {
          let rmaItem = this.rmaItems[rmaItemIndex];
          if (
              parseInt(rmaItemToDeleteId) !== parseInt(rmaItem['customerOrderLineId'])
          ) {
            newRmaItems.push(rmaItem);
          }
        }
        this.rmaItems = newRmaItems;
      }
    },
    handleDrop(_dropFiles, acceptedFiles, _rejectedFiles) {

      // Fetch key and question index from the combined key we added to the image input as id
      let elementId = event.target.id;
      elementId = elementId.replace('input_', '');
      let keyAndQuestionId = elementId.split('_');
      let key = keyAndQuestionId[0];
      let questionId = keyAndQuestionId[1];

      // TODO: show error messages of needed
      console.debug('-- _dropFiles --');
      console.debug(_dropFiles);
      console.debug('-- acceptedFiles --');
      console.debug(acceptedFiles);
      console.debug('-- _rejectedFiles --');
      console.debug(_rejectedFiles);

      for (let acceptedFileIndex in acceptedFiles) {
        let acceptedFile = acceptedFiles[acceptedFileIndex];
        this.setRmaItemAnswerData(key, questionId, acceptedFile, true)
      }
    },
    thumbnailSource(file) {
      const validImageTypes = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png'];
      return validImageTypes.includes(file.type)
          ? window.URL.createObjectURL(file)
          : '';
    },
    getQuestionInputName(key, reasonQuestionId, prefix) {
      return prefix + '_' + key + '_' + reasonQuestionId;
    },
    getQuestionDataSelectOptions(questionData) {
      let result = [];
      for (let index in questionData) {
        let questionAnswer = questionData[index];
        result.push({
          'value' : index,
          'label' : questionAnswer,
          //'helpText' : reasonQuestion.title,
        })
      }
      return result;
    },
    initQuestionAnswerData() {
      // Request rma answer data, this fixes data types between strings and arrays when binding inputs
      axios.post(this.host + '/api/v1/rma-answer-data?pid=' + this.pid + '&lang=' + this.lang, {
        'rmaItems': this.rmaItems,
        'lang': this.lang
      })
          .then(response => {
            //console.debug(response);
            this.rmaItems = response.data
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele){
        return ele !== value;
      });
    },
    setRmaItemAnswerData(rmaItemKey, questionId, value, array) {
      for (let rmaItemIndex in this.rmaItems) {
        let rmaItem = this.rmaItems[rmaItemIndex];
        if (rmaItem.key === rmaItemKey) {
          for (let answerIndex in rmaItem['answerData']) {
            if (parseInt(answerIndex) === parseInt(questionId)) {
              if (array) {
                this.rmaItems[rmaItemIndex]['answerData'][answerIndex].push(value);
              } else {
                this.rmaItems[rmaItemIndex]['answerData'][answerIndex] = value;
              }
            }
          }
        }
      }
    },
    getAllRmaQuestionsAnswered() {
      let allAnswered = true;
      for (let rmaItemIndex in this.rmaItems) {
        let rmaItem = this.rmaItems[rmaItemIndex];
        for (let answerIndex in rmaItem['answerData']) {
          let answerData = this.rmaItems[rmaItemIndex]['answerData'][answerIndex];
          if (rmaItem.questionData[answerIndex]['mandatory'] && (answerData === '' || answerData.length === 0)) {
            allAnswered = false;
          }
        }
      }
      console.debug(this.config.generalConditionsUrl !== null);
      console.debug(this.acceptedGeneralConditions !== true);

      // Block if general conditions url is available and not accepted
      if (this.config.generalConditionsUrl !== null && this.acceptedGeneralConditions !== true) {
          allAnswered = false;
      }
      return allAnswered;
    },
    submitRma(e) {
      e.preventDefault();
      this.setLoading(true);
      const data = new FormData();
      // Add data array
      data.append('token', this.orderToken);
      data.append('email', this.email);
      data.append('rmaItems', JSON.stringify(this.rmaItems));
      data.append('comments', this.comments);
      data.append('lang', this.lang);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      }
      axios.post(this.host + '/api/v1/rma-submit?pid=' + this.pid + '&lang=' + this.lang, data, config)
          .then(response => {
              if (response.data.messages && response.data.messages.length > 0) {
                  this.messages = response.data.messages;
              } else {
                  this.setCurrentPage(5);
                  this.rmaSubmitted = true;
                  let hid = response.data.hid;
                  this.submitRmaAttachments(hid);
              }
              this.setLoading(false);
          })
          .catch(function (error) {
              console.log(error);
              this.setLoading(false);
          });
    },
    submitRmaAttachments(hid) {

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      }

      // Fetch images and add them
      // let attachments = [];
      for (let rmaItemIndex in this.rmaItems) {
        let rmaItem = this.rmaItems[rmaItemIndex];
        for (let questionIndex in rmaItem['questionData']) {
          let question = rmaItem['questionData'][questionIndex];
          if (question.answerType === 'image' || question.answerType === 'video') {
            let attachmentsKey = this.getQuestionInputName(rmaItem.key, questionIndex, 'attachments');
            for( var i = 0; i < this.rmaItems[rmaItemIndex].answerData[question.id].length; i++ ) {
              const data = new FormData();
              // Apply another random string, otherwise names in post would be equal
              let randStr = '_' + (Math.random() + 1).toString(36).substring(7);
              let file = this.rmaItems[rmaItemIndex].answerData[question.id][i];
              data.append(attachmentsKey + randStr, file);
              axios.post(this.host + '/api/v1/rma-attachment-submit/' + hid, data, config)
                  .then(response => {
                    console.log(response);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
            }
          }
        }

        this.setCurrentPage(6);
      }
    },
    closeRmaModel() {
      this.currentCustomerOrderLine = null;
    },
    handlePrevious() {
      this.setCurrentPage(this.currentPage - 1)
    },
    handleNext() {
      this.setCurrentPage(this.currentPage + 1)
    },
    setCurrentPage(page) {
      // Page should be between 1 and 7 (last two are received and uploading page).
      if (page > 0 && page < 7) {
        this.currentPage = page;
      }
      // When page 1 reached, reset everything
      if (page === 1) {
        this.reset();
      } else if (page === 2) {
        this.returnLinesSelected = false;
      } else if (page === 3) {
        // this.returnRmaItemsEntered = false;
      }
      // Update lastPage
      if (page > this.lastPage) {
        this.lastPage = page;
      }
    },
    setMessagesFromResponse(response) {
      this.messages = (response.data.messages) ? response.data.messages : [];
    },
    setMessage(message) {
      this.messages = [message];
    },
    clearMessages() {
      this.messages = [];
    },
    setLoading(i) {
      this.isLoading = i;
    }
  },
  async created() {

    let app = document.getElementById('app');
    // Get oid from app div data attribute
    let host = app.getAttribute('data-host');
    //let lang = app.getAttribute('data-lang');
    //let debug = app.getAttribute('data-debug');
    if (host) {
      this.host = host;
    }

    // Set platform id and language, default to english
    //this.lang = (lang !== null) ? lang : 'en';
    //this.debug = (debug !== null);

    let that = this;

    // Get url params
    let urlParams = new URLSearchParams(window.location.search);
    //let configUrl = 'http://' + this.host + '/api/v1/config?';
    let configUrl = this.host + '/api/v1/config?';
    if (urlParams.get('lang')) {
      configUrl += '&lang=' + urlParams.get('lang');
    }
    if (urlParams.get('pid')) {
      configUrl += '&pid=' + urlParams.get('pid');
    }

    //axios.get('http://' + this.host + '/api/v1/config?pid=' + this.pid)
    axios.get(configUrl)
      .then(response => {

        if (response.data) {
          this.config = response.data.rma
          this.apiUri = this.config.apiUri;
          this.langOptions = this.config.locales;
          this.vendor = this.config.vendor;
          this.showComments = this.config.showComments;
          this.platform = this.config.platform;
          this.currencySign = this.config.currencySign;
          this.translations = this.config.translations;
          this.pid = this.config.pid;
          app.setAttribute('data-pid', this.pid); // Add as attribute, is fetched in next interceptor
          this.setLoading(false);

          // Update favicon and title
          const faviconLink = document.querySelector("link[rel='icon']");
          faviconLink.href = this.config.faviconUrl;
          document.title = this.translations.APP_TITLE;

          // Add X-Pid header to each request
          axios.interceptors.request.use(function (config) {
            config.headers['X-Pid'] = app.getAttribute('data-pid');
            that.setLoading(true);
            return config;
          });
          axios.interceptors.response.use(function(response) {
            // Do something with response data
            // console.log('Done with Ajax call');
            that.clearMessages();
            that.setLoading(false);
            return response;
          }, function(error) {
            // Do something with response error
            console.log(error);
            that.setMessage(error.message);
            that.setLoading(false);
            return Promise.reject(error);
          });

          // Set default values of inputs from url if possible and auto login
          let urlParams = new URLSearchParams(window.location.search);
          if (urlParams.get('token')) {
            this.orderToken = urlParams.get('token');
            this.email = urlParams.get('email');
            if (urlParams.get('lang')) {
              this.lang = urlParams.get('lang');
            } else {
              this.lang = 'en';
            }
            this.getOrder();
          }

        } else {
          that.setMessage(response);
          this.setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        that.setMessage(error.message);
      });
  },
  mounted: function() {
  },
  setup() {
  }
}

</script>
